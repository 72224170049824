import type { Loglevel, SourceType } from '@cbc/videoplayer';
import type { CbcLoadVideoConfig, CbcPlayerConfig } from '@cbc-player/core';
import type { TrackingDevice } from '../../../types/tracking';
import { TCF_VENDOR_IDS } from '../../lib/Sourcepoint';
import { getVideoDuration, getVideoEncoding } from './helper/utils';
import type { VideoProps } from './VideoPlayerDefault';

export const geoBlockingMessage = 'Dieses Video ist in deinem Land leider nicht verfügbar.';

export const getVpShare = (path: string) => {
  let pathArray = path
    .split('/')
    .filter((n) => n)
    .filter((n) => n !== 'rtl_portal');

  if (pathArray.length > 3) {
    pathArray = pathArray.slice(0, 3);
  }

  if (pathArray?.[0]?.toLowerCase().match(/^(show|sendungen)$/)) {
    if (pathArray?.[1]?.toLowerCase().match(/^(show|real-life|serie|comedy|spezial|life|news)$/)) {
      const showName = pathArray[2];
      pathArray = [showName];
    } else {
      pathArray = pathArray.slice(1, 2);
    }
  }

  return `rtl_rtl/${pathArray.join('_')}`;
};

export const createPlayerConfig = (
  consentedVendorIds: Array<string> = [],
  contentPartnerId = '',
  device: TrackingDevice = 'Smartphone',
  isDebug?: boolean
): CbcPlayerConfig => ({
  page: {
    autoplayButton: true,
  },
  tracking: {
    offer: 'rtl',
    videoService: 'vms',
    heartbeat: {
      beatInterval: 30,
    },
    infOnline: {
      st: 'rtl',
    },
    googleAnalytics: {
      googleAnalyticsId: consentedVendorIds.includes(TCF_VENDOR_IDS.GOOGLE_ANALYTICS) ? 'UA-2898674-6' : undefined,
      beatInterval: 5,
      contentPartnerId,
    },
    device: device,
    nielsen: {
      vcId: 'c01',
      sfCode: 'eu',
      prod: 'vc',
      apId: 'PC5B26A44-7BEB-4F51-9076-4AD1A9620F0F',
    },
  },
  features: {
    bugSnag: false,
    homad: {
      enabled: true,
      clientConfigUrl: 'https://cdn.static-fra.de/now/sc/rtl.de.clientConfig.json',
    },
    ...(isDebug ? { logging: { level: 'DEBUG' as Loglevel } } : null),
  },
  constraints: {
    geoBlockingVod: {
      enabled: true,
      errorText: geoBlockingMessage,
    },
  },
});

export const createLoadVideoConfig = ({
  video,
  targeting,
  tcString,
  startType = 0,
  agof,
  ivw = '/rtl_portal/news',
  ads = true,
  noPreroll = false,
  noPostroll = false,
}: {
  video: VideoProps;
  targeting: string | null;
  tcString: string;
  startType: number;
  agof: string;
  ivw?: string;
  ads?: boolean;
  noPreroll?: boolean;
  noPostroll?: boolean;
}): CbcLoadVideoConfig => ({
  advertising: {
    adCall: {
      share: getVpShare(ivw),
      contentPartner: 'cp_rtli_',
      customParameters: ['ang_api=7', `consent=${tcString}`],
      targetingParameters: targeting ? decodeURIComponent(targeting).split('&') : [],
    },
    preRoll: {
      enabled: ads && !noPreroll,
    },
    postRoll: {
      enabled: ads && !noPostroll && getVideoDuration(video.fields.encoding) > 30,
    },
    midRoll: {
      enabled: ads && getVideoDuration(video.fields.encoding) > 480,
      offsets: [180],
    },
    nonLinear: {
      enabled: false,
    },
  },
  meta: {
    id: video.sourceId ?? video.id,
    ivw,
    title: decodeURIComponent(video.fields.title),
    supplier: video.fields.producer,
    createDate: video.createdAt,
    agof: agof.replace(/^.{2}/g, 'dv'),
    comment: `/${ivw.split('/')[1]}/videoabrufe_vod/${ivw.split('/').slice(2).join('/')}`,
    offer: 'rtl',
    videoService: 'vms',
    duration: Math.round(getVideoDuration(video.fields.encoding)),
    payStatus: 0,
  },
  sources: {
    type: 'vod' as SourceType,
    progressiveUrl: getVideoEncoding(video.fields.encoding)?.value['content-url'].value ?? '',
    hlsUrl: getVideoEncoding(video.fields.encoding, 'hls')?.value['content-url'].value ?? '',
    dashUrl: getVideoEncoding(video.fields.encoding, 'dash')?.value['content-url'].value ?? '',
  },
  behavior: {
    startType: startType,
  },
});
