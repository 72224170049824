/* eslint-disable jsx-a11y/interactive-supports-focus */
import type { Dispatch, MutableRefObject, SetStateAction } from 'react';
import type CbcVideoplayer from '@cbc/videoplayer/src/cbcVideoplayer';
import { clsx } from 'clsx';
import { formatSecondsToMinutes } from '../../../utils/time';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import {
  changePlayerVolume,
  getSeekBarId,
  handleBackgroundClick,
  isMuted,
  keyUp,
  mouseMove,
  muteOrUnmuteVideo,
  seekBackward,
  seekBarHover,
  seekByClick,
  seekForward,
  toggleFullscreen,
  handlePlayClick,
  startContent,
} from './functions';
import { VideoPlayerControlsLoading } from './VideoPlayerControlsLoading';
import styles from './VideoPlayerControlsStateless.module.scss';

export type PlayerControlsState = {
  player: CbcVideoplayer;
  isReady: boolean;
  setVisibleTimeout: Dispatch<SetStateAction<NodeJS.Timeout | null>>;
  visibleTimeout: NodeJS.Timeout | null;
  isRepeatable: boolean;
  setIsRepeatable: Dispatch<SetStateAction<boolean>>;
  playTime: number;
  hoveredPlayTime: number;
  setHoveredPlayTime: Dispatch<SetStateAction<number>>;
  setVolumeSliderValue: Dispatch<SetStateAction<number>>;
  volumeSliderValue: number;
  isFullscreen: boolean;
  hasVideoStarted: boolean;
  loading: boolean;
  pausedOnReturnToVideo: MutableRefObject<boolean>;
  isVisibleRef: MutableRefObject<boolean>;
  isPauseButton: boolean;
};

export const UnStartedVideoControls = (playerControlsState: PlayerControlsState) => (
  <>
    <button
      className={styles.controlWrapperClickContainer}
      onClick={() => {
        startContent(playerControlsState);
      }}
      aria-label="Video starten"
    />
    <Typography className={styles.startVideoButton} variant="s" as="div">
      <Icon className={styles.startVideoIcon} name="playOutline" size={23} />
      Video starten
    </Typography>
  </>
);

const StartedVideoControls = (playerControlsState: PlayerControlsState) => {
  const {
    isVisibleRef,
    hasVideoStarted,
    isFullscreen,
    player,
    playTime,
    isRepeatable,
    setHoveredPlayTime,
    hoveredPlayTime,
    volumeSliderValue,
    setVolumeSliderValue,
    isPauseButton,
  } = playerControlsState;
  const visible = isVisibleRef.current;
  const pointerEventStyle = visible || !player.isPlaying() ? 'auto' : 'none';
  const seekIconSize = isFullscreen ? 66 : 32;

  const startButton = isRepeatable ? 'repeatVideo' : 'play';
  const mainControllButton = isPauseButton ? 'pause' : startButton;

  return (
    <>
      <button
        className={styles.controlWrapperClickContainer}
        onClick={() => {
          handleBackgroundClick(playerControlsState);
        }}
        onTouchEnd={(e) => {
          handleBackgroundClick(playerControlsState);
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseMove={() => {
          mouseMove(playerControlsState);
        }}
        onKeyUp={(event) => keyUp(event, playerControlsState)}
      />
      {hasVideoStarted && !player.isAd() && (
        <button
          className={clsx(styles.seekButton, { [styles.fullscreen]: isFullscreen })}
          onClick={() => seekBackward(player, playTime)}
          onTouchEnd={(e) => {
            seekBackward(player, playTime);
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{
            pointerEvents: pointerEventStyle,
          }}
        >
          <Icon name="seekBackward15" size={seekIconSize} />
        </button>
      )}
      {hasVideoStarted && (
        <button
          className={clsx(styles.playButton, { [styles.fullscreen]: isFullscreen })}
          onClick={() => {
            handlePlayClick(playerControlsState);
          }}
          onTouchEnd={(e) => {
            handlePlayClick(playerControlsState);
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{
            pointerEvents: pointerEventStyle,
          }}
        >
          <Icon name={mainControllButton} size={isFullscreen ? 132 : 50} />
        </button>
      )}
      {hasVideoStarted && !player.isAd() && (
        <button
          className={clsx(styles.seekButton, { [styles.fullscreen]: isFullscreen })}
          onClick={() => {
            seekForward(player, playTime);
          }}
          onTouchEnd={(e) => {
            seekForward(player, playTime);
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{
            pointerEvents: pointerEventStyle,
          }}
        >
          <Icon name="seekForward15" size={seekIconSize} />
        </button>
      )}
      {hasVideoStarted && !isRepeatable && (
        <div className={clsx(styles.controlWrapperBottom, { [styles.fullscreen]: isFullscreen })}>
          <button
            className={styles.controlWrapperClickContainer}
            onClick={() => {
              handleBackgroundClick(playerControlsState);
            }}
            onTouchEnd={(e) => {
              handleBackgroundClick(playerControlsState);
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <button
            className={clsx(styles.seekBar, { [styles.fullscreen]: isFullscreen })}
            onClick={(event) => seekByClick(event, player)}
            onTouchEnd={(event) => {
              seekByClick(event, player);
              event.preventDefault();
              event.stopPropagation();
            }}
            onMouseMove={(event) => seekBarHover(event, player, setHoveredPlayTime)}
            id={getSeekBarId(player.playerElementId)}
          >
            <div
              className={clsx(styles.seekBarProgress, {
                [styles.isAd]: player.isAd(),
                [styles.fullscreen]: isFullscreen,
              })}
              style={{ width: `${(playTime / player.getDuration()) * 100}%` }}
            />
            {!player.isAd() && (
              <Typography
                className={styles.seekBarPlayTime}
                variant="4xs"
                as="div"
                textAlign="center"
                style={{ left: `${(hoveredPlayTime / player.getDuration()) * 100}%` }}
              >
                {formatSecondsToMinutes(hoveredPlayTime)}
              </Typography>
            )}
          </button>
          <div className={styles.controlButtonsWrapper}>
            <div className={styles.flexWrapper}>
              <button
                className={clsx(styles.playButtonSmall, { [styles.fullscreen]: isFullscreen })}
                onClick={() => {
                  handlePlayClick(playerControlsState);
                }}
                onTouchEnd={(e) => {
                  handlePlayClick(playerControlsState);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Icon name={mainControllButton} size={isFullscreen ? 36 : 24} />
              </button>
              <div className={clsx(styles.timeLabel, { [styles.isAd]: player.isAd() })}>
                {player.isAd() ? 'Werbung' : formatSecondsToMinutes(playTime)}
              </div>
            </div>
            <div className={styles.flexWrapper}>
              <div className={styles.volumeWrapper}>
                <button
                  className={clsx(styles.volumeSpeakerButton, { [styles.fullscreen]: isFullscreen })}
                  onClick={() => muteOrUnmuteVideo(player)}
                  onTouchEnd={(e) => {
                    muteOrUnmuteVideo(player);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Icon name={isMuted(player) ? 'speakerMuted' : 'speaker'} size={isFullscreen ? 30 : 20} />
                </button>
                <input
                  className={styles.volumeSlider}
                  type="range"
                  min="0"
                  max="100"
                  defaultValue={player.getVolume()}
                  onInput={(event) => {
                    changePlayerVolume(event, player, setVolumeSliderValue);
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  style={{
                    background: `linear-gradient(to right, var(--white) 0%, var(--white) ${volumeSliderValue}%, var(--gray-dark) ${volumeSliderValue}%, var(--gray-dark) 100% )`,
                  }}
                />
              </div>
              <button
                className={clsx(styles.fullscreenButton, { [styles.fullscreen]: isFullscreen })}
                onClick={() => {
                  isVisibleRef.current = true;
                  toggleFullscreen(player, isFullscreen);
                }}
                onTouchEnd={(e) => {
                  isVisibleRef.current = true;
                  toggleFullscreen(player, isFullscreen);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Icon name={isFullscreen ? 'fullscreenExit' : 'fullscreenEnter'} size={isFullscreen ? 30 : 20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const renderControls = (playerControlsState: PlayerControlsState, visible: boolean) => {
  const { player, hasVideoStarted } = playerControlsState;
  return hasVideoStarted ? (
    <div
      className={styles.controlWrapper}
      style={{
        opacity: visible || !player.isPlaying() ? 1 : 0,
        cursor: visible || !player.isPlaying() ? 'pointer' : undefined,
      }}
    >
      <StartedVideoControls {...playerControlsState} />
    </div>
  ) : (
    <div className={styles.controlWrapper}>
      <UnStartedVideoControls {...playerControlsState} />
    </div>
  );
};

export const VideoPlayerControlsStateless = (playerControlsState: PlayerControlsState) => {
  const { player, isReady, loading, isVisibleRef } = playerControlsState;

  const visible = isVisibleRef.current;

  return (
    <>
      {player && isReady ? renderControls(playerControlsState, visible) : null}
      {loading && <VideoPlayerControlsLoading />}
    </>
  );
};
